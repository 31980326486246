import React, { useState } from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';

const ExposureGuideContent = () => {
  return (
    <div>
      <h1>Safe Sun Exposure Time</h1>

      <p>
        Safe sun exposure time is determined by factors such as your skin type, UV index,
        altitude, latitude, and time of year. We take a liberal approach to sun exposure,
        using the Minimal Erythemal Dose (MED) as the limiting factor. MED is the amount
        of UV radiation your skin can tolerate before it shows visible signs of redness.
        Not only is this harmless, it is actually beneficial, so don't be afraid of a
        little redness. There is also no need to jump straight to maximum exposure time
        either. Feel free to ease your way in. Start by cutting the recommended exposure
        time in half and slowy increasing, paying careful attention to how your body
        reacts.
      </p>

      <h2>How We Calculate Your Safe Sun Exposure Time</h2>
      <p>We calculate the safe exposure time using the following factors:</p>
      <div className="custom-list">
        <div className="custom-list-item">
          <strong>UV Index:</strong> A measure of the sun's UV radiation. The higher the
          UV index, the stronger the radiation.
        </div>
        <div className="custom-list-item">
          <strong>Altitude:</strong> Higher altitudes increase UV exposure, roughly 10%
          for every 1,000 meters.
        </div>
        <div className="custom-list-item">
          <strong>Latitude:</strong> The closer you are to the equator, the stronger the
          UV radiation. We adjust for low, mid, and high latitudes.
        </div>
        <div className="custom-list-item">
          <strong>Time of Year:</strong> UV radiation is stronger during summer months.
          Depending on whether you are in the northern or southern hemisphere, we adjust
          for the current season.
        </div>
      </div>

      <h2>Understanding MED and Erythema</h2>
      <p>
        As mentioned above, your skin's sensitivity to UV radiation is quantified by the{' '}
        <strong>Minimal Erythemal Dose (MED)</strong>; the maximum amount of sun you can
        handle before visible signs of redness, or <strong>erythema</strong>, sets in. Not
        all redness indicates skin damage.
        <strong>Level 0 erythema</strong> is a result of increased blood flow to the skin
        due to the release of <strong>nitric oxide</strong>, which occurs in response to
        UV exposure, particularly UV-A. This initial redness is a sign of vasodilation,
        not sunburn, and it typically fades quickly after sun exposure.
      </p>
      <p>
        Vasodilation is beneficial because it reduces your blood pressure, and is
        associated lower incidence of cardiovascular events such as heart attack and
        stroke. While nitric oxide indiced vasodilation is beneficial, continued exposure
        beynond this point can lead to more intense levels of erythema, including sunburn,
        where skin damage occurs. It's essential to monitor your skin for signs of
        higher-level erythema, where the redness persists, and the skin feels hot or
        painful to the touch and get into the shade before this occurs.
      </p>
      <p>
        Everyone's skin reacts differently to UV radiation, and these calculations serve
        only as guidelines. It is crucial to monitor your body's response to the sun,
        particularly when spending extended time outdoors. Adjust your sun exposure time
        based on how your skin feels and reacts. Always be mindful of signs like redness
        or discomfort, and protect your skin accordingly. The face and neck are are three
        time more sensitive to UV than the rest of your body, so wear a hat or take
        frequent shade breaks to protect yourself.
      </p>

      <h2>Guideline Limitations</h2>
      <p>
        These exposure time estimates provide a starting point for safe sun exposure.
        However, every individual’s skin responds differently to UV radiation, and factors
        like hydration, medications, diet, or pre-existing health conditions may also
        influence your sensitivity to the sun. Always prioritize your body’s unique
        reactions, and adjust your time in the sun accordingly and remember--this is for
        educational purposes only. This is not medical advice.
      </p>
    </div>
  );
};

export default function ExposureGuideModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();

  const modalResultsClass = darkTheme ? 'modal-results modal-dark' : 'modal-results';

  return (
    <Modal
      show={isModalOpen(ModalTypes.ExposureGuide)}
      darkTheme={darkTheme}
      sunType={sunType}
      onClose={closeModal}
    >
      <ExposureGuideContent />
    </Modal>
  );
}
