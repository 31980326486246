import axios from 'axios';
import logger from './logger';
import { loadDataFromCache, loadLocationDataFromCache } from './local-storage';

const DefaultLat = 40.7306;
const DefaultLng = -73.9352;
const FeetPerMeter = 3.28084;

export const DefaultLocationData = {
  location: 'New York, New York, United States',
  coordinates: { latitude: DefaultLat, longitude: DefaultLng },
  timezone: 'America/New_York',
  elevation: 0,
};

export function isDefaultCoordinates(coords) {
  return coords.longitude === DefaultLng && coords.latitude === DefaultLat;
}

export function getGeolocation() {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          logger.info('Found geolocation data', JSON.stringify(position, null, 2));
          resolve(position);
          // reject('Manually rejected geolocation');
        },
        (error) => {
          logger.error('Error detecting location', error.message);
          reject(error);
        },
        {
          enableHighAccuracy: true, // Optional: Use high accuracy if available
          timeout: 10000, // Optional: Timeout after 10 seconds
          maximumAge: 0, // Optional: Do not cache previous location
        },
      );
    } else {
      logger.error('Geolocation is not supported by this browser.');
      reject(new Error('Geolocation is not supported by this browser.'));
    }
  });
}

export async function searchLocation(location) {
  return searchMapBoxLocation(location);
}

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoicGV0ZXJjb3dhbiIsImEiOiJjbHoxcGx3NHMweHA2MnFwcTFmMWRjcm95In0.0BRDIsXNmHxk5buiEn4CxA';

const createLocationObject = (feature) => {
  const context = feature.context || [];
  const city = feature.text || '';
  const state = context.find((ctx) => ctx.id.includes('region'))?.text || '';
  let country = context.find((ctx) => ctx.id.includes('country'))?.text || '';

  // Modify "US" to "USA"
  if (country === 'United States') {
    country = 'USA';
  }

  let displayName = city;
  if (state) displayName += `, ${state}`;
  if (country) displayName += `, ${country}`;

  const [lon, lat] = feature.geometry.coordinates;

  return {
    id: feature.id,
    displayName,
    lat: lat,
    lon: lon,
  };
};

export async function searchMapBoxLocation(cityName) {
  try {
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(cityName)}.json`,
      {
        params: {
          access_token: MAPBOX_ACCESS_TOKEN,
          types: 'place',
        },
      },
    );

    if (response.data && response.data.features.length > 0) {
      return response.data.features.map((feature) => {
        return createLocationObject(feature);
      });
    } else {
      return [];
    }
  } catch (error) {
    logger.error('Error searching for the location', error.message);
    throw new Error('Error searching for the location');
  }
}

export async function getCoordinates(location) {
  try {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(location)}`,
    );
    if (response.data && response.data.length > 0) {
      const { lat, lon } = response.data[0];
      return {
        location,
        latitude: parseFloat(lat),
        longitude: parseFloat(lon),
      };
    } else {
      return DefaultLocationData.coordinates;
    }
  } catch (error) {
    logger.log(error);
    throw new Error('Error fetching coordinates for the location');
  }
}

export const getLocationFromCoords = async (latitude, longitude) => {
  let response;
  try {
    response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json`,
      {
        params: {
          access_token: MAPBOX_ACCESS_TOKEN,
        },
      },
    );
  } catch (e) {
    logger.warn('Unable to retrieve location from coords', e.message);
    throw new Error('Unable to retrieve location from coords');
  }

  if (response && response.data && response.data.features.length > 0) {
    const places = response.data.features.find((f) => f.place_type.includes('place'));
    logger.info('Locations found from coords', JSON.stringify(places, null, 2));
    const feature = places;
    return createLocationObject(feature);
  } else {
    logger.warn('Location not found');
    throw new Error('No location data found.');
  }
};

const GOOGLE_API_KEY = 'AIzaSyCYQcadcgHmGDZYWKxgaQv-B9UlR3LnISs'; // Replace with your actual Google API key

export const getTimeZone = async (latitude, longitude) => {
  const defaultTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  try {
    const timestamp = Math.floor(Date.now() / 1000);
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json`,
      {
        params: {
          location: `${latitude},${longitude}`,
          timestamp: timestamp,
          key: GOOGLE_API_KEY,
        },
      },
    );

    if (response.data.status !== 'OK') {
      logger.warn('Timezone response invalid', response.data.status);
      return defaultTz;
    }
    logger.info('Found timezone', response.data.timeZoneId);

    return response.data.timeZoneId;
  } catch (e) {
    logger.warn('Unable to retrieve timezone', e.message);
    return defaultTz;
  }
};

export async function fetchLocationData(useCache = true) {
  if (useCache) {
    const cachedLocationData = await loadLocationDataFromCache();
    if (cachedLocationData) {
      return cachedLocationData;
    }
  }

  const {
    coords: { latitude, longitude },
  } = await getGeolocation();

  if (isNaN(latitude) || isNaN(longitude)) {
    throw new Error('Invalid lat/lng returned');
  }

  const [location, timezone, elevation] = await Promise.all([
    getLocationFromCoords(latitude, longitude),
    getTimeZone(latitude, longitude),
    fetchElevation(latitude, longitude),
  ]);

  const coordinates = {
    latitude: Math.round(latitude * 10000) / 10000,
    longitude: Math.round(longitude * 10000) / 10000,
  };
  return { location: location, coordinates, timezone, elevation };
}

export async function fetchElevation(latitude, longitude) {
  const openElevationURL = `https://api.open-elevation.com/api/v1/lookup`;

  try {
    const response = await axios.get(openElevationURL, {
      params: {
        locations: `${latitude},${longitude}`,
      },
    });

    const data = response.data;

    if (data.results && data.results.length > 0) {
      logger.info('Elevation data found', data.results[0].elevation);
      const elevation = data.results[0].elevation;
      return elevation * FeetPerMeter;
    } else {
      logger.warn('No elevation data found for the given location.');
      return 0;
    }
  } catch (error) {
    logger.error('Error fetching elevation data:', error.message);
  }
}
