import React, { useState, useEffect, useRef } from 'react';
import '../css/HamburgerMenu.css'; // Import the CSS file
import { usePage } from '../providers/page-provider';
import { getSunTypeGradient } from '../domain/sun-type';
import { useInfoPanel } from './info-panel';

const HamburgerMenu = ({ menuItems, darkTheme, sunType }) => {
  const { goToPage, isActivePage } = usePage();
  const [isOpen, setIsOpen] = useState(false);
  const { hideInfoPanel } = useInfoPanel();
  const gradient = getSunTypeGradient(sunType);
  const barClass = darkTheme ? 'bar bar-dark' : 'bar';

  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Handle clicks outside of the menu to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} className="hamburger-container">
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span className={barClass}></span>
        <span className={barClass}></span>
        <span className={barClass}></span>
      </div>

      <nav className={`menu ${isOpen ? 'open' : ''}`} style={{ background: gradient }}>
        <ul>
          {menuItems
            .sort((a, b) => a.index - b.index)
            .map((item, index) => {
              const isActiveItem = isActivePage(item.link);

              const onClick = () => {
                if (isActivePage(item.link)) {
                  return;
                }
                if (typeof item.link === 'function') {
                  item.link();
                } else {
                  hideInfoPanel();
                  goToPage(item.link);
                }
              };

              return (
                <li
                  key={item.key}
                  className={`menu-item ${index === 0 ? 'top-item' : ''}`}
                >
                  <div
                    onClick={onClick}
                    className={`item-name ${isActiveItem ? 'active-item' : ''}`}
                  >
                    {item.name}
                  </div>
                </li>
              );
            })}
        </ul>
      </nav>
    </div>
  );
};

export default HamburgerMenu;
