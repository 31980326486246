import React, { useState } from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import { useSolarClock } from '../../providers/solar-clock-provider';
import Modal from './modal';
import { fetchLocationData, searchLocation } from '../../utils/geo';
import ThreeDotsBounce from '../../icons/three-dots-bounce';
import LocationTroubleShootingModal from './location-troubleshooting-modal';
import { SunTypes } from '../sun_path';

function LocationForm({
  location,
  onSuccess,
  onFailure,
  onSubmit,
  detectLocationFailed,
}) {
  const [inputLocation, setInputLocation] = useState(location);
  const [loading, setLoading] = useState(undefined);

  const onChangeLocation = (event) => {
    setInputLocation(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSubmit();
    setLoading('search');
    const locationsList = await searchLocation(inputLocation);
    setLoading(undefined);
    onSuccess(locationsList);
  };

  const handleDetectLocation = async (event) => {
    event.preventDefault();
    onSubmit();
    setLoading('detect');
    try {
      const location = await fetchLocationData(false);
      onSuccess([location.location]);
    } catch (e) {
      onFailure(
        'There was an error detecting your location. Please try again, or search manually for your location.',
      );
    }
    setLoading(undefined);
  };

  return (
    <div className="signUpForm">
      <form onSubmit={handleSubmit}>
        <div className="emailInput">
          <input
            type="text"
            value={inputLocation}
            onChange={onChangeLocation}
            className="form-input form-input-medium"
          />
          <button
            type="submit"
            className="gradient-button button-medium"
            disabled={loading !== undefined}
          >
            {loading === 'search' ? <ThreeDotsBounce /> : 'Search'}
          </button>
        </div>
        {!detectLocationFailed && (
          <div className="emailInput">
            <button
              onClick={handleDetectLocation}
              className="gradient-button button-medium"
              disabled={loading !== undefined}
            >
              {loading === 'detect' ? <ThreeDotsBounce /> : 'Current Location'}
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

function LocationItem({ location, onSelect }) {
  const { id, displayName } = location;
  return (
    <div key={id} className="modal-result-item" onClick={() => onSelect(location)}>
      {displayName}
    </div>
  );
}

export function LocationSearch({ darkTheme, onSelect }) {
  const [locations, setLocations] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showTroubleshooting, setShowTroubleshooting] = useState(false);
  const { location, selectLocation } = useSolarClock();
  const { detectLocationFailed } = useSolarClock();

  const modalResultsClass = darkTheme ? 'modal-results modal-dark' : 'modal-results';

  function clearState() {
    setLocations(undefined);
    setErrorMessage(undefined);
  }

  function onSelectLocation(location) {
    selectLocation(location);
    if (onSelect) {
      onSelect();
    }
    clearState();
  }

  const onSuccess = (locations) => setLocations(locations);
  const onFailure = (msg) => setErrorMessage(msg);

  const handleOpenTroubleshooting = () => {
    setShowTroubleshooting(true);
  };
  return (
    <>
      {showTroubleshooting && (
        <LocationTroubleShootingModal
          darkTheme={darkTheme}
          sunType={SunTypes.Twilight}
          isOpen={true}
          onClose={() => setShowTroubleshooting(false)}
        />
      )}
      <h2>{detectLocationFailed ? 'Set your Location' : 'Find a Location'}</h2>
      {detectLocationFailed && (
        <div>
          We were unable to detect your location. Please search for your location below.
          For further help, view our{' '}
          <span className="link" onClick={handleOpenTroubleshooting}>
            Troubleshooting page
          </span>
          .
        </div>
      )}
      <LocationForm
        location={location}
        onSuccess={onSuccess}
        onFailure={onFailure}
        onSubmit={clearState}
        detectLocationFailed={detectLocationFailed}
      />
      <hr />
      {errorMessage && <div>{errorMessage}</div>}
      {locations && (
        <div className={modalResultsClass}>
          {locations.map((location) => (
            <LocationItem
              key={location.id}
              location={location}
              onSelect={onSelectLocation}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default function LocationSearchModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();

  return (
    <Modal
      show={isModalOpen(ModalTypes.LocationSearch)}
      onClose={closeModal}
      darkTheme={darkTheme}
      sunType={sunType}
    >
      <LocationSearch darkTheme={darkTheme} onSelect={closeModal} />
    </Modal>
  );
}
