import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SunriseSun } from '../icons/sun-svg-icon';
import AppHeader from '../components/app_header';
import { SunTypes } from '../components/sun_path';
import { getCircadianEventBySunType } from '../components/circadian_event';
import { useBackground } from '../hooks/use-background-animation';
import { ModalTypes, useModal } from '../providers/modal-provider';
import { useSolarClock } from '../providers/solar-clock-provider';
import { getSunType } from '../domain/sun-type';
import useBrowserInfo from '../hooks/use-browser-info';
import SunIcon, { isSunIcon, isMoonIcon } from '../icons/sun_icon';
import { InfoPanelState, useInfoPanel } from '../components/info-panel';
import UvNavigation from '../components/uv-nav';
import { calculateSafeSunExposureTime } from '../utils/uv-utils';
import { useUvContext } from '../providers/uv-provider';
import { convertMinutesToHoursAndMinutes, formatDayTimeAndZone } from '../utils/time';
import '../css/UV.css';
import { sleep } from '../utils/async';
import { getFormattedTime, isToday } from '../utils/time';
import { UvProvider } from '../providers/uv-provider';
import { HeaderTime } from '../components/floating-time';
import { DateTime } from 'luxon';
import MoonPhase from '../icons/moon-svg';
import SiteNav from '../components/site-nav';
import LocationPage from './location-page';
import { usePage } from '../providers/page-provider';

// Helper function to find the closest time based on a comparison condition (either earlier or later)
const findClosestTime = (times, targetDateTime, comparator) => {
  // Convert the object to an array of entries ([key, value]) and filter based on the comparator
  const filteredEntries = Object.entries(times).filter(([key, time]) =>
    comparator(time, targetDateTime),
  );

  if (filteredEntries.length === 0) {
    return null; // Return null if no valid times are found
  }

  // Find the entry with the minimum time difference
  const closestEntry = filteredEntries.reduce((closest, current) => {
    const [closestKey, closestTime] = closest;
    const [currentKey, currentTime] = current;
    return Math.abs(currentTime.diff(targetDateTime).milliseconds) <
      Math.abs(closestTime.diff(targetDateTime).milliseconds)
      ? current
      : closest;
  });

  return closestEntry; // Return the [key, value] of the closest time
};

// Find the closest time that is later than the target
const findClosestLaterTime = (times, targetDateTime) => {
  return findClosestTime(times, targetDateTime, (time, target) => time > target);
};

// Find the closest time that is earlier than the target
const findClosestEarlierTime = (times, targetDateTime) => {
  return findClosestTime(times, targetDateTime, (time, target) => time < target);
};

export const SunTimeLabels = {
  Daybreak: 'daybreak',
  Dawn: 'dawn',
  Sunrise: 'sunrise',
  UvaRise: 'uvaRise',
  UvbRise: 'uvbRise',
  SolarNoon: 'solarNoon',
  UvbSet: 'uvbSet',
  UvaSet: 'uvaSet',
  Sunset: 'sunset',
  Dusk: 'dusk',
  Nightfall: 'nightfall',
  Nadir: 'nadir',
};

// Enum/Hash for UV Risk Levels
const UVRiskLevels = {
  NONE: 0,
  LOW: 4,
  MODERATE: 6,
  HIGH: 8,
  VERY_HIGH: 11,
  EXTREME: 20,
};

const getUvRiskValue = (uvIndex) => {
  if (uvIndex <= 2) {
    return UVRiskLevels.LOW;
  } else if (uvIndex >= 3 && uvIndex <= 5) {
    return UVRiskLevels.MODERATE;
  } else if (uvIndex >= 6 && uvIndex <= 7) {
    return UVRiskLevels.HIGH;
  } else if (uvIndex >= 8 && uvIndex <= 10) {
    return UVRiskLevels.VERY_HIGH;
  } else if (uvIndex >= 11) {
    return UVRiskLevels.EXTREME;
  }
  return UVRiskLevels.NONE;
};

const calculateUVStatus = (
  times,
  earlierEntry,
  laterEntry,
  currentTime,
  uvIndex,
  maxUv,
  maxUvTime,
  timezone,
  day,
) => {
  const [earlierLabel, earlierTime] = earlierEntry;

  // Determine the current and maximum UV risks
  const uvRisk = getUvRiskValue(uvIndex);
  const maxUvRisk = getUvRiskValue(maxUv);
  const uvbExists = times[SunTimeLabels.UvbRise];

  // Pre-computed values
  const uvaRiseTime = getFormattedTime(times[SunTimeLabels.UvaRise]);
  const uvbRiseTime = uvbExists ? getFormattedTime(times[SunTimeLabels.UvbRise]) : '';
  const maxUvTimeFormatted = getFormattedTime(maxUvTime);
  const uvbSetTime = getFormattedTime(times[SunTimeLabels.UvbSet]);
  const uvaSetTime = getFormattedTime(times[SunTimeLabels.UvaSet]);
  const sunsetTime = getFormattedTime(times[SunTimeLabels.Sunset]);

  // Message templates
  const minimalRiskMessage = (tense) => `The risk ${tense} minimal ${day.toLowerCase()}.`;
  const lowRiskMessage = (tense) => `The risk ${tense} low ${day.toLowerCase()}.`;
  const moderateRiskMessage = (tense) =>
    `There ${tense} potential for risk. Be mindful of sun exposure.`;
  const highRiskMessage = (tense) =>
    `Risk ${tense} moderate. Closely monitor your exposure and take shade breaks as needed.`;
  const extremeRiskMessage = (tense) =>
    `Risk ${tense} likely. You may need to limit direct sun exposure.`;

  // Decide whether to use "is" or "will be"
  const getTense = (isCurrent) => (isCurrent ? 'is' : 'will be');
  const today = isToday(currentTime, timezone);
  const risingTense = today ? `is` : 'is expexcted to be';

  // UVA-specific messages
  const uvaWillAppearMessage = `UVA will appear around ${uvaRiseTime}`;
  const uvbAppearMessage = `UVB will appear around ${uvbRiseTime}`;

  // Custom content for each sun event based on UV risk

  // Sunrise and Below (Nadir, Daybreak, Dawn, Sunrise)
  if (
    [
      SunTimeLabels.Nadir,
      SunTimeLabels.Daybreak,
      SunTimeLabels.Dawn,
      SunTimeLabels.Sunrise,
    ].includes(earlierLabel)
  ) {
    const tense = getTense(false); // Forecasting risk
    if (maxUv < UVRiskLevels.LOW) {
      return `${uvaWillAppearMessage}, but ${minimalRiskMessage(tense).toLowerCase()}`;
    } else if (maxUv < UVRiskLevels.MODERATE) {
      return `${uvaWillAppearMessage}, but ${lowRiskMessage(tense)}`;
    } else if (maxUv < UVRiskLevels.HIGH) {
      return `${uvaWillAppearMessage}. The forecasted max UV is ${maxUv}. ${moderateRiskMessage(tense)}`;
    } else if (maxUv < UVRiskLevels.EXTREME) {
      return `${uvaWillAppearMessage}. The forecasted max UV is ${maxUv}. ${highRiskMessage(tense)}`;
    } else {
      return `${uvaWillAppearMessage}. The forecasted max UV is ${maxUv}. ${extremeRiskMessage(tense)}`;
    }
  }

  if (!uvbExists) {
    if (earlierLabel === SunTimeLabels.UvaRise) {
      return `UV ${risingTense} rising, but UVB will not appear ${day.toLowerCase()}, so ${minimalRiskMessage('will be').toLowerCase()}`;
    }

    if (earlierLabel === SunTimeLabels.SolarNoon) {
      return `UV ${risingTense} present and near its peak of ${maxUv}, and will likely decline soon. ${minimalRiskMessage('is')}`;
    }
  } else {
    if (earlierLabel === SunTimeLabels.UvaRise) {
      const tense = getTense(false); // Forecasting risk
      if (maxUv < UVRiskLevels.LOW) {
        return `UV ${risingTense} rising. ${uvbAppearMessage}, with a max of ${maxUv}. ${minimalRiskMessage(tense)}`;
      } else if (maxUv < UVRiskLevels.MODERATE) {
        return `UV ${risingTense} rising. ${uvbAppearMessage}, with a max of ${maxUv}. ${lowRiskMessage(tense)}`;
      } else if (maxUv < UVRiskLevels.HIGH) {
        return `UV ${risingTense} rising. ${uvbAppearMessage}, with a max of ${maxUv}. ${moderateRiskMessage(tense)}`;
      } else if (maxUv < UVRiskLevels.EXTREME) {
        return `UV ${risingTense} rising. ${uvbAppearMessage}, with a max of ${maxUv}. ${highRiskMessage(tense)}`;
      } else {
        return `UV ${risingTense} rising. ${uvbAppearMessage}, with a max of ${maxUv}. ${extremeRiskMessage(tense)}`;
      }
    }

    if (earlierLabel === SunTimeLabels.UvbRise) {
      const tense = getTense(true); // Current risk
      if (maxUv < UVRiskLevels.LOW) {
        return `UVB ${risingTense} rising, but the forecast is low, with a max of ${maxUv}. ${minimalRiskMessage(tense)}`;
      } else if (maxUv < UVRiskLevels.MODERATE) {
        return `UVB ${risingTense} rising, but the forecast is moderate, with a max of ${maxUv}. ${lowRiskMessage(tense)}`;
      } else if (maxUv < UVRiskLevels.HIGH) {
        return `UVB ${risingTense} rising, and the forecast is high, with a max of ${maxUv}. ${moderateRiskMessage(tense)}`;
      } else if (maxUv < UVRiskLevels.EXTREME) {
        return `UVB ${risingTense} rising, and the forecast is high, with a max of ${maxUv}. ${highRiskMessage(tense)}`;
      } else {
        return `UV ${risingTense} rising, and the forecast is extremely high, with a max of ${maxUv}. ${extremeRiskMessage(tense)}`;
      }
    }

    if (earlierLabel === SunTimeLabels.SolarNoon) {
      const tense = getTense(true); // Current risk
      if (maxUv < UVRiskLevels.LOW) {
        return `UVB ${risingTense} present and near its peak of ${maxUv}, and will likely decline soon. ${minimalRiskMessage(tense)}`;
      } else if (maxUv < UVRiskLevels.MODERATE) {
        return `UVB ${risingTense} present and near its peak of ${maxUv}, and will likely decline soon. ${lowRiskMessage(tense)}`;
      } else if (maxUv < UVRiskLevels.HIGH) {
        return `UVB ${risingTense} near its peak of ${maxUv}, and will likely decline soon. ${moderateRiskMessage(tense)} until closer to ${uvbSetTime}.`;
      } else if (maxUv < UVRiskLevels.EXTREME) {
        return `UVB ${risingTense} near its peak of ${maxUv}, and will likely decline soon. ${highRiskMessage(tense)} until closer to ${uvbSetTime}.`;
      } else {
        return `UVB ${risingTense} near its peak of ${maxUv}, and will likely decline soon. ${extremeRiskMessage(tense)} until closer to ${uvbSetTime}.`;
      }
    }

    if (earlierLabel === SunTimeLabels.UvbSet) {
      return `UV strength ${risingTense} waning, and will disappear by ${uvaSetTime}.`;
    }
  }

  if (earlierLabel === SunTimeLabels.UvaSet) {
    const tense = today ? 'has' : 'will have';
    return `UV ${tense} disappeared. The sun will be up until ${sunsetTime}.`;
  }

  if (
    [SunTimeLabels.Sunset, SunTimeLabels.Dusk, SunTimeLabels.Nightfall].includes(
      earlierLabel,
    )
  ) {
    const tense = today ? 'is' : 'will be';
    return `The sun ${tense} down for the day. ${today ? 'Check back tomorrow.' : ''}`;
  }

  return '';
};

function UltraVioletPage() {
  const {
    timezone,
    isLocationLoading,
    activeEvent,
    activateEvent,
    clearEvent,
    today,
    sunTimes,
    displayDate,
    isNight,
    moonPhase,
    coordinates,
    elevation,
    isDateToday,
  } = useSolarClock();

  const { skinType, uvIndex, maxUv, maxUvTime, isUvLoaded } = useUvContext();
  const uvDay = isDateToday(displayDate) ? 'Today' : 'Tomorrow';
  const uvIndexLabel = isDateToday(displayDate) ? 'Current' : activeEvent;

  const sunType = useMemo(() => {
    return getSunType(sunTimes, displayDate);
  }, [sunTimes, displayDate]);

  const isDarkTheme = useMemo(() => {
    return isNight(displayDate);
  }, [displayDate]);

  const contentClass = isDarkTheme ? 'page-content dark-mode-text-color' : 'page-content';
  const { isMobileLayout } = useBrowserInfo();
  const { debug } = usePage();
  const { openModal } = useModal();
  const openExposureGuideModal = useCallback(
    () => openModal(ModalTypes.ExposureGuide),
    [],
  );
  const openSkinTypeModal = useCallback(() => openModal(ModalTypes.SkinType), []);

  useBackground(sunType);

  const closestLaterSunTime = useMemo(() => {
    return findClosestLaterTime(sunTimes, displayDate);
  }, [sunTimes, displayDate]);
  const closestEarlierSunTime = useMemo(() => {
    return findClosestEarlierTime(sunTimes, displayDate);
  }, [sunTimes, displayDate]);

  const uvStatus = useMemo(() => {
    return calculateUVStatus(
      sunTimes,
      closestEarlierSunTime,
      closestLaterSunTime,
      displayDate,
      uvIndex,
      maxUv,
      maxUvTime,
      timezone,
      uvDay,
    );
  }, [
    sunTimes,
    closestEarlierSunTime,
    closestLaterSunTime,
    displayDate,
    uvIndex,
    maxUv,
    maxUvTime,
    timezone,
    uvDay,
    isUvLoaded,
  ]);

  const handleShowExposureGuide = () => {
    openExposureGuideModal();
  };

  const displayUvIndex = useMemo(() => {
    return isUvLoaded && uvIndex ? uvIndex : '-';
  }, [isUvLoaded, uvIndex]);

  const displaySafeExposureTime = useMemo(() => {
    if (
      !isUvLoaded ||
      uvIndex == null ||
      !elevation ||
      !coordinates?.latitude ||
      !displayDate
    ) {
      return '-';
    }

    if (!skinType) {
      return '';
    }
    if (uvIndex === 0) {
      return 'No limit';
    }

    const safeExposureTime = calculateSafeSunExposureTime(
      uvIndex,
      elevation,
      coordinates.latitude,
      displayDate,
      skinType,
    );

    // Compare the safeExposureTime with the difference between `date` and `sunTimes.uvaSet`
    const difference = new Date(sunTimes.uvaSet - new Date(displayDate)) / (1000 * 60); // difference in minutes
    if (difference <= safeExposureTime) {
      return 'No limit';
    }

    return convertMinutesToHoursAndMinutes(safeExposureTime);
  }, [uvIndex, elevation, coordinates.latitude, skinType, displayDate, sunTimes.uvaSet]);

  return (
    <div className="App">
      <AppHeader
        darkTheme={!isSunIcon(sunType)}
        sunType={sunType}
        isLoaded={isUvLoaded}
      />
      <SiteNav darkTheme={isDarkTheme} isLoaded={!isUvLoaded} sunType={sunType} />
      <UvNavigation
        darkTheme={isDarkTheme}
        isLocationLoading={isLocationLoading}
        sunType={sunType}
      />
      <div>
        <div className={contentClass}>
          <div className="centered-column">
            {!isDateToday(displayDate) && (
              <div className="floating-uv-index">Tomorrow's Forecast</div>
            )}
            <div className="floating-uv-index">
              {uvIndexLabel} UV: {uvIndex}
            </div>
            <div className="floating-uv-index">
              Max UV: {maxUv} at {getFormattedTime(maxUvTime)}
            </div>
          </div>
          <div className="floating-exposure-guide" onClick={handleShowExposureGuide}>
            <div>
              Safe Exposure Time: {displaySafeExposureTime}{' '}
              {!skinType && (
                <span className="skinTypesPrompt">
                  Select your skin type above to for safe exposure time.
                </span>
              )}
            </div>
          </div>
          <div className="floating-uv-info">
            {uvStatus}
            {debug && (
              <div>
                <strong>
                  isUvLoaded: {isUvLoaded?.toString()}
                  <br />
                  uvIndex: {uvIndex}
                  <br />
                  elevation: {elevation}
                  <br />
                  coordinates.latitude: {coordinates?.latitude}
                  <br />
                  displayDate: {formatDayTimeAndZone(displayDate)}
                </strong>
              </div>
            )}
          </div>
          <div className="uv-page-sun">
            {isSunIcon(sunType) && (
              <SunIcon radius={75} sunType={sunType} isMobile={isMobileLayout} />
            )}
            {isMoonIcon(sunType) && <MoonPhase radius={125} phase={moonPhase} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function UltraVioletPageWrapper({ onLoad, isLoaded }) {
  return (
    <LocationPage>
      <UltraVioletPage onLoad={onLoad} isLoaded={isLoaded} />;
    </LocationPage>
  );
}
