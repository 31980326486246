import React, { useState, useEffect } from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';
import { SkinType } from '../../utils/uv-utils';
import { useUvContext } from '../../providers/uv-provider';

const SkinTypes = () => {
  const [selectedSkinType, setSelectedSkinType] = useState('');
  const { skinType, saveSkinType } = useUvContext();

  const handleSelectChange = (e) => {
    const st = e.target.value;
    setSelectedSkinType(st);
    saveSkinType(st);
  };

  useEffect(() => {
    setSelectedSkinType(skinType);
  }, [skinType]);

  return (
    <div className="content">
      <h1>Fitzpatrick Skin Types</h1>
      <p>
        The Fitzpatrick skin type classification is a way to categorize how different skin
        tones respond to UV exposure. The system ranges from Type I to Type VI, with Type
        I being the lightest and Type VI being the darkest. Identifying your skin type can
        help you better understand your susceptibility to sunburn and how to protect
        yourself from over or under exposure.
      </p>
      <p>
        It is important to note that your skin type isn't necessarily static. You can
        increase your sun tolerance with strategic exposure, circadian rhythm
        optimization, dietary changes, and red light supplementation, among other factors.
        With proper guidance--and time--even the most fair skinned people can learn to
        tolerate strong solar conditions.
      </p>

      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="skin-type-select" className="skinTypesListLabel">
          Select your Fitzpatrick skin type:
        </label>
        <select
          id="skin-type-select"
          value={selectedSkinType}
          onChange={handleSelectChange}
          className="skinTypesList"
        >
          <option value="">--Select Skin Type--</option>
          <option value={SkinType.I}>Type I</option>
          <option value={SkinType.II}>Type II</option>
          <option value={SkinType.III}>Type III</option>
          <option value={SkinType.IV}>Type IV</option>
          <option value={SkinType.V}>Type V</option>
          <option value={SkinType.VI}>Type VI</option>
        </select>
      </div>

      {selectedSkinType && (
        <p>
          You have selected <strong>{selectedSkinType}</strong> as your skin type.
        </p>
      )}

      <table>
        <thead>
          <tr>
            <th rowSpan="2">Type I</th>
            <td>Often burns, rarely tans. May freckle.</td>
          </tr>
          <tr>
            <td>
              Very fair skin, often with freckles. Red or blond hair, blue or green eyes.
            </td>
          </tr>
        </thead>
      </table>

      <table>
        <thead>
          <tr>
            <th rowSpan="2">Type II</th>
            <td>Burns easily, tans minimally.</td>
          </tr>
          <tr>
            <td>Fair skin, usually with light hair and light-colored eyes.</td>
          </tr>
        </thead>
      </table>

      <table>
        <thead>
          <tr>
            <th rowSpan="2">Type III</th>
            <td>Sometimes burns, tans uniformly.</td>
          </tr>
          <tr>
            <td>Medium to olive skin, darker hair, brown or hazel eyes.</td>
          </tr>
        </thead>
      </table>

      <table>
        <thead>
          <tr>
            <th rowSpan="2">Type IV</th>
            <td>Rarely burns, tans easily and well.</td>
          </tr>
          <tr>
            <td>Olive to light brown skin, dark hair and eyes.</td>
          </tr>
        </thead>
      </table>

      <table>
        <thead>
          <tr>
            <th rowSpan="2">Type V</th>
            <td>Very rarely burns, tans easily.</td>
          </tr>
          <tr>
            <td>Brown skin, dark hair and eyes.</td>
          </tr>
        </thead>
      </table>

      <table>
        <thead>
          <tr>
            <th rowSpan="2">Type VI</th>
            <td>Never burns, deeply pigmented.</td>
          </tr>
          <tr>
            <td>Dark brown to black skin, dark hair and eyes.</td>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default function SkinTypeModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();

  return (
    <Modal
      show={isModalOpen(ModalTypes.SkinType)}
      darkTheme={darkTheme}
      sunType={sunType}
      onClose={closeModal}
    >
      <SkinTypes />
    </Modal>
  );
}
