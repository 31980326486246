import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import {
  loadSkinTypeDataFromCache,
  saveSkinTypeDataToCache,
} from '../utils/local-storage';
import { useSolarClock } from '../providers/solar-clock-provider';
import { fetchUVIndex, fetchUVForecast, UvUpdateInterval } from '../utils/uv-utils';
import { useInterval } from '../hooks/use-interval';
import { formatDayTimeAndZone } from '../utils/time';
import { DateTime } from 'luxon';

const UvContext = createContext();

export const UvProvider = ({ children }) => {
  const [skinType, setSkinType] = useState(undefined);
  const [uvIndex, setUvIndex] = useState(undefined);
  const [maxUv, setMaxUv] = useState(undefined);
  const [maxUvTime, setMaxUvTime] = useState(undefined);
  const [uvForecast, setUvForecast] = useState([]);
  const [isUvLoaded, setIsUvLoaded] = useState(false);

  const { timezone, displayDate, isDateToday, isClockDataLoaded } = useSolarClock();

  const count = useInterval(UvUpdateInterval);

  const { coordinates } = useSolarClock();

  useEffect(() => {
    void loadSkinTypeDataFromCache().then((st) => {
      if (st) {
        setSkinType(st);
      }
    });
  }, []);

  useEffect(() => {
    if (!isClockDataLoaded) {
      return;
    }

    if (isDateToday(displayDate)) {
      fetchUVIndex(coordinates.latitude, coordinates.longitude, displayDate)
        .then((data) => {
          setUvIndex(Math.round(data.uvIndex));
          setMaxUv(Math.round(data.maxUv));
          setMaxUvTime(DateTime.fromISO(data.maxUvTime).setZone(timezone));
        })
        .then(() => setIsUvLoaded(true));
      // fetchUVForecast(coordinates.latitude, coordinates.longitude, displayDate)
    } else {
      fetchUVForecast(coordinates.latitude, coordinates.longitude, displayDate)
        .then((data) => {
          setUvForecast(data);

          // Find the maximum UV and corresponding time from the forecast
          let maxUv = -Infinity;
          let maxUvTime = null;
          let closestUv = null;
          let closestTimeDiff = Infinity;

          data.forEach((item) => {
            const itemTime = DateTime.fromISO(item.uvTime).setZone(timezone);
            const itemUv = item.uvIndex;

            // Update max UV and time
            if (itemUv > maxUv) {
              maxUv = itemUv;
              maxUvTime = itemTime;
            }

            // Find the UV closest to the displayDate time
            const timeDiff = Math.abs(itemTime.diff(displayDate, 'minutes').minutes);
            if (timeDiff < closestTimeDiff) {
              closestTimeDiff = timeDiff;
              closestUv = itemUv;
            }
          });

          setMaxUv(Math.round(maxUv));
          setMaxUvTime(maxUvTime);
          setUvIndex(Math.round(closestUv));
        })
        .then(() => setIsUvLoaded(true));
    }
  }, [
    coordinates.latitude,
    coordinates.longitude,
    displayDate.year,
    displayDate.month,
    displayDate.day,
    isClockDataLoaded,
  ]);

  const saveSkinType = (newSkinType) => {
    saveSkinTypeDataToCache(newSkinType);
    setSkinType(newSkinType);
  };

  return (
    <UvContext.Provider
      value={{ skinType, saveSkinType, uvIndex, maxUv, maxUvTime, isUvLoaded }}
    >
      {children}
    </UvContext.Provider>
  );
};

// Custom hook to use the UvContext in components
export const useUvContext = () => {
  return useContext(UvContext);
};
