import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import SolarClock from './pages/solar-clock';
import UltraVioletPage from './pages/ultra-violet';
import CompassPage from './pages/compass-page';
import './css/App.css';
import CircadianScience from './pages/circadian-science';
import SolarEvents from './pages/solar-events';
import useBrowserInfo from './hooks/use-browser-info';
import { usePage, Pages } from './providers/page-provider';
import SplashPage from './pages/splash-page';

export default function App() {
  const [isHomeLoaded, setIsHomeLoaded] = useState(false);
  const [isClockLoaded, setIsClockLoaded] = useState(false);
  const [isUvLoaded, setIsUvLoaded] = useState(false);
  const { isActivePage } = usePage();

  return (
    <>
      {isActivePage(Pages.Home) && <SplashPage />}
      {isActivePage(Pages.Clock) && (
        <SolarEvents onLoad={() => setIsClockLoaded(true)} isLoaded={isClockLoaded} />
      )}
      {isActivePage(Pages.Uv) && (
        <UltraVioletPage onLoad={() => setIsUvLoaded(true)} isLoaded={isUvLoaded} />
      )}
      {isActivePage(Pages.Compass) && (
        <CompassPage onLoad={() => setIsClockLoaded(true)} isLoaded={isClockLoaded} />
      )}
      {isActivePage(Pages.LearnMore) && <CircadianScience isLoaded={true} />}
    </>
  );
}
